/* eslint-disable */
var BPB_WAHLBINGO = BPB_WAHLBINGO || {};

BPB_WAHLBINGO = (function (window, document, undefined) {
  // ====================================== VALUES ======================================

  /// / --- BREAKS --- ////

  const v_tablet_landscape = 1280;
        const v_tablet_portrait = 960;
        const v_smartphone_landscape = 560;
        const v_smartphone_portrait = 320;

  /// / --- DIMENSIONS --- ////

  let v_screenWidth = 0;
        let v_screenHeight = 0;
        let v_bodyHeight = 0;
        let v_scrollDistanceTop = 0;

  /// / --- DOM --- ////

  let body = document.querySelector('body');

  // ====================================== HELPER FUNCTIONS ======================================

  /// / --- DOM --- ////

  const h_hasClass = function (obj, str) {
    let classStr = obj.className;
    let regex = new RegExp('\\b'+str.replace(/\_/g,'\_').replace(/\-/g,'\-')+'\\b', 'g');

    if (typeof classStr === 'string' && str) {
      return classStr.match(regex);
    }
  };

  const h_removeClass = function (el, str) {
    let regex = new RegExp(
      '(\\s|\\b)' + str.replace(/\_/g, '_').replace(/\-/g, '-') + '\\b',
      'g'
    );
    el.className = el.className.replace(regex, '');
  };

  const h_parentsWithClass = function (el, str) {
    let parents = [];
            let current = el;

    while (current.parentNode.nodeType === Node.ELEMENT_NODE) {
      if (h_hasClass(current.parentNode, str)) {
        parents.push(current.parentNode);
      }
      current = current.parentNode;
    }
    return parents;
  };

  /// / --- DIMENSIONS --- ////

  const h_screenWidth = function () {
    v_screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
  };

  const h_screenHeight = function () {
    v_screenHeight =
      window.innerHeight > 0 ? window.innerHeight : screen.height;
  };

  const h_bodyHeight = function () {
    v_bodyHeight = body.clientHeight;
  };

  const h_scrollDistanceTop = function () {
    v_scrollDistanceTop = window.scrollY;
  };

  /// / --- UTILITY --- ////

  const h_getRandomInt = function (min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // ====================================== BINGO LOGIC ======================================

  let bingo = {
    tiles: document.querySelectorAll('.js_tile'),
    tilesButtons: document.querySelectorAll('.js_tile .o_tile-button'),
    tilesBacks: document.querySelectorAll('.o_tile__back'),
    tileInners: document.querySelectorAll('.js_tile-inner'),
    field: document.querySelector('.js_bingo-field'),
    message: document.querySelector('.js_bingo-msg'),

    squareMatrix: [0, 0, 0, 0, 0, 0, 0, 0, 0],

    squareMatrixLength: 0,
    squareBase: 0,
    squareDiagonal: false,
    squareMatrixMiddle_index: null,

    currentSquareIndex: null,
    currentRow: null,
    currentRowPosition: null,

    isBingo: 0,

    init: function () {
      this.squareMatrixLength = this.squareMatrix.length;
      this.squareBase = Math.sqrt(this.squareMatrixLength);
      this.squareDiagonal = this.squareBase % 2;
      this.squareMatrixMiddle_index = (function () {
        if (bingo.squareDiagonal) {
          return Math.ceil(bingo.squareMatrixLength / 2) - 1;
        }
        return false;
      })();

      tile.syncUpHeight();
      bingo.syncUpHeight();

      for (var i = 0; i < bingo.tiles.length; i++) {
        bingo.tiles[i].tileIndex = i;
        bingo.disseminateContent(bingo.tiles[i]);
        bingo.tilesButtons[i].addEventListener('click', tile.flip);
        bingo.tilesBacks[i].addEventListener('click', this.flipTileBack);
      }
    },

    flipTileBack: function (event) {
      // only flip tile back if user did not clicked on the "learn more" dialog button
      if (event.target.className.includes('js_modal-link')) return
      tile.flip(event)
    },

    disseminateContent: function (el) {
      const frontTitle = el.querySelector('.js_tile-front_title'),
      backTitle = el.querySelector('.js_tile-back_title'),
      backShort = el.querySelector('.js_tile-back_short'),
      backLong = el.querySelector('.js_tile-back_long');

      const bingoGrundgesetz = document.querySelector('.bingo-grundgesetz');
      if (bingoGrundgesetz) {
        const randomIndex = h_getRandomInt(0,tile.contentGrundgesetz.length-1);
        frontTitle.innerHTML = tile.contentGrundgesetz[randomIndex].thesis;
        backShort.innerHTML = tile.contentGrundgesetz[randomIndex].short;
        tile.contentGrundgesetz.splice(randomIndex, 1);

      } else {
        const randomIndex = h_getRandomInt(0,tile.content.length-1);
        frontTitle.innerHTML = tile.content[randomIndex].thesis;

        if (backTitle) {
          tile.content[randomIndex].reaction ? backTitle.innerHTML = tile.content[randomIndex].reaction : backTitle.innerHTML = '';
        }

        backShort.innerHTML = tile.content[randomIndex].short;
        backLong.innerHTML =
          tile.content[randomIndex].long + tile.content[randomIndex].links;

        tile.content.splice(randomIndex, 1);
      }
    },

    bingoEvaluation: function () {
      this.isBingo = 0;

      // if (v_screenWidth > v_smartphone_landscape) {
        if (v_screenWidth > 500) {
        this.squareMatrixPatternCheck();
      }

      if (this.isBingo) {
        // if (v_screenWidth > v_smartphone_landscape) {
        if (v_screenWidth > 500) {
          this.bingoAlert();
        }
      }
    },

    bingoAlert: function () {
      bingo.message.innerHTML = 'BINGO!';
      setTimeout(function () {
        bingo.field.className += ' o_bingo-alert';
        bingo.message.style.marginLeft =
          '-' + bingo.message.clientWidth / 2 + 'px';
        bingo.message.style.marginTop =
          '-' + bingo.message.clientHeight / 2 + 'px';
        bingo.message.style.opacity = 1;
      }, 600);
      setTimeout(function () {
        bingo.message.style.opacity = 0;
        bingo.message.innerHTML = '';
        h_removeClass(bingo.field, 'o_bingo-alert');
      }, 3000);
    },

    squareMatrixPatternCheck: function () {
      // CHECK neighbouring positions in currentRow whose position is >= 0.
      this.checkForHorizontal();

      // CHECK row positions same as currentRowPosition.
      this.checkForVertical();

      // IF squareDiagonal
      if (bingo.squareDiagonal) {
        this.checkForDiagonals();
      }
    },

    checkForHorizontal: function () {
      let currentRowStart =
          bingo.currentRow * this.squareBase - this.squareBase;
                let nextRowStart = bingo.currentRow * this.squareBase;
                let hits = [];

      for (let i = currentRowStart; i < nextRowStart; i++) {
        if (this.squareMatrix[i]) {
          hits.push(this.tiles[i]);
        }
      }

      if (hits.length === this.squareBase) {
        this.isBingo = 1;
        for (let i = 0; i < hits.length; i++) {
          hits[i].className += ' o_tile--highlight';
        }
      }
    },

    checkForVertical: function () {
      const hits = [];

      for (let i = 1; i < this.squareBase + 1; i++) {
        let currentRowStart = i * this.squareBase - this.squareBase;
                    let targetIndex = currentRowStart + (bingo.currentRowPosition - 1);

        if (this.squareMatrix[targetIndex]) {
          hits.push(this.tiles[targetIndex]);
        }
      }

      if (hits.length === this.squareBase) {
        this.isBingo = 1;
        for (let i = 0; i < hits.length; i++) {
          hits[i].className += ' o_tile--highlight';
        }
      }
    },

    checkForDiagonals: function () {
      let hitsDiagonalTopLeftToBottomRight = [];
                let hitsDiagonalBottomLeftToTopRight = [];
                let isPartOfDiagonalTopLeftToBottomRight = 0;
                let isPartOfDiagonalBottomLeftToTopRight = 0;

      for (let i = 0; i < bingo.squareBase; i++) {
        let diagonalTopLeftToBottomRight =
            (i + 1) * this.squareBase - this.squareBase + i;
                    let diagonalBottomLeftToTopRight = ((i+1) * this.squareBase) - 1 - i;

        if (bingo.currentSquareIndex === diagonalTopLeftToBottomRight) {
          isPartOfDiagonalTopLeftToBottomRight = 1;
        }

        if (bingo.currentSquareIndex === diagonalBottomLeftToTopRight) {
          isPartOfDiagonalBottomLeftToTopRight = 1;
        }

        // console.log("row: "+(i+1)+" diagonalTopLeftToBottomRight: "+diagonalTopLeftToBottomRight);
        // console.log("row: "+(i+1)+" diagonalBottomLeftToTopRight: "+diagonalBottomLeftToTopRight);

        if (diagonalTopLeftToBottomRight === bingo.squareMatrixMiddle_index) {
          if (bingo.squareMatrix[diagonalTopLeftToBottomRight]) {
            hitsDiagonalTopLeftToBottomRight.push(
              this.tiles[diagonalTopLeftToBottomRight]
            );
            hitsDiagonalBottomLeftToTopRight.push(
              this.tiles[diagonalTopLeftToBottomRight]
            );
          }
          continue;
        }

        if (bingo.squareMatrix[diagonalTopLeftToBottomRight]) {
          hitsDiagonalTopLeftToBottomRight.push(
            this.tiles[diagonalTopLeftToBottomRight]
          );
        }
        if (bingo.squareMatrix[diagonalBottomLeftToTopRight]) {
          hitsDiagonalBottomLeftToTopRight.push(
            this.tiles[diagonalBottomLeftToTopRight]
          );
        }
      }

      if (isPartOfDiagonalTopLeftToBottomRight) {
        if (hitsDiagonalTopLeftToBottomRight.length === this.squareBase) {
          this.isBingo = 1;
          for (let i = 0; i < hitsDiagonalTopLeftToBottomRight.length; i++) {
            hitsDiagonalTopLeftToBottomRight[i].className +=
              ' o_tile--highlight';
          }
        }
      }

      if (isPartOfDiagonalBottomLeftToTopRight) {
        if (hitsDiagonalBottomLeftToTopRight.length === this.squareBase) {
          this.isBingo = 1;
          for (let i = 0; i < hitsDiagonalBottomLeftToTopRight.length; i++) {
            hitsDiagonalBottomLeftToTopRight[i].className +=
              ' o_tile--highlight';
          }
        }
      }
    },

    syncUpHeight: function () {
      // bingo.field.style.height = bingo.field.clientWidth + 'px';
    },
  };

  let tile = {
    syncUpHeight: function () {
      // let h = bingo.tiles[0].clientWidth + 'px';
      // for (let i = 0; i < bingo.tiles.length; i++) {
      //   bingo.tiles[i].style.minHeight = h;
      // }
      // for (let i = 0; i < bingo.tileInners.length; i++) {
      //   bingo.tileInners[i].style.minHeight = h;
      // }
    },

    flip: function (e) {
      let el = e.target;
                let tileOuter = h_parentsWithClass(el, 'js_tile-outer')[0];
                let tileFront;
                let tileBack;

      if (bingo.isBingo) {
        for (let i = 0; i < bingo.tiles.length; i++) {
          h_removeClass(bingo.tiles[i], 'o_tile--highlight');
        }
      }

      bingo.currentSquareIndex =
        typeof el.tileIndex === 'number'
          ? el.tileIndex
          : h_parentsWithClass(el, 'js_tile ')[0].tileIndex;
      bingo.currentRowPosition =
        (bingo.currentSquareIndex % bingo.squareBase) + 1; // between 1 and squareBase
      bingo.currentRow = Math.floor(
        1 + bingo.currentSquareIndex / bingo.squareBase
      ); // between 1 and squareBase

      bingo.squareMatrix[bingo.currentSquareIndex] = bingo.squareMatrix[
        bingo.currentSquareIndex
      ]
        ? 0
        : 1;

      tileFront =
        bingo.tiles[bingo.currentSquareIndex].querySelector('.js_tile-front');
      tileBack =
        bingo.tiles[bingo.currentSquareIndex].querySelector('.js_tile-back');

      if (!h_hasClass(tileOuter, 'js_tile--clicked')) {
        el.setAttribute('aria-expanded', 'true');
        tileBack.setAttribute('aria-hidden', 'false');
        tileOuter.className += ' js_tile--clicked o_tile-outer--clicked';
        bingo.bingoEvaluation();
      } else {
        el.setAttribute('aria-expanded', 'false');
        tileBack.setAttribute('aria-hidden', 'true');
        tileBack.style.display = 'block';
        h_removeClass(tileOuter, 'js_tile--clicked');
        h_removeClass(tileOuter, 'o_tile-outer--clicked');
        setTimeout(function () {
          tileBack.style.display = '';
        }, 600);
      }
    },

    content: [
      {
        thesis:     'Ich weiß nicht, wie das geht.',

        reaction:   'Dann informier\' Dich.',
        short:      'Wer zum ersten Mal wählt, findet das manchmal schwierig. Dabei ist das gar nicht so kompliziert – wenn Du die wichtigen Schritte kennst.',

        long:       '<p>Bei der Bundestagswahl entscheiden wir darüber, wer in den Bundestag kommt und welche Partei oder Parteien uns zukünftig regieren werden. Drei Millionen Menschen treffen diese Entscheidung am 24. September zum ersten Mal. Viele wissen also noch nicht aus eigener Erfahrung, wie Wählen geht. Die wichtigsten Schritte:</p>' +
                    '<p>Alle Wahlberechtigten erhalten vor der Wahl mit der Post die <b>Wahlbenachrichtigung</b>. Dort steht, wann und wo Du wählen kannst und wie Du schon vor der Wahl (z.B. per Briefwahl) Deine Stimme abgeben kannst. Mit dieser Benachrichtigung oder auch einfach nur mit Deinem Personalausweis gehst Du am <b>Wahltag</b>, dem 24. September, zwischen 8 und 18 Uhr ins Wahllokal. Das liegt meistens nur wenige Gehminuten von Deiner Wohnung entfernt.</p>' +
                    '<p>Dort bekommst Du den <b>Stimmzettel</b>. Auf dem gibt es zwei Spalten, in denen Du jeweils nur ein Kreuz machen darfst. Dabei ist die Zweitstimme (rechte Spalte) für die Zusammensetzung des Bundestags entscheidend. Mit der Erststimme (linke Spalte) entscheidest Du, wer aus Deinem Wahlkreis in den Bundestag kommt. Falls Du unsicher bist, was du tun musst, helfen Dir die freiwilligen Wahlhelferinnen und Wahlhelfer weiter. Den Stimmzettel füllst Du aus und wirfst ihn die die Wahlurne. Das wars schon.</p>' +
                    '<p>Wenn Du ganz genau wissen willst, wie wählen geht, <a href="http://wahlhilfe.bpb.de" target="_blank" title="Zur Wahlhilfe der bpb">schau dir unsere Wahlhilfe an<a/>.</p>',
        links:      '<ul>' +
                        '<li><a href="http://www.bpb.de/249001" target="_blank">Wie funktioniert die Bundestagswahl? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/599" target="_blank">Was sind Erst- und Zweitstimme? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/185748" target="_blank">Was machen Wahlhelfer? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/39331" target="_blank">Was ist der Bundestag? (bpb.de)</a></li>' +
                    '</ul>'
    },
    {
        thesis:     'Am Wahltag bin ich nicht da.',

        reaction:   'Kein Problem!',
        short:      'Bundestagswahl ist eigentlich am 24. September. Du kannst aber schon vorher wählen – direkt im Wahlamt oder bequem per Briefwahl, egal wo du bist.',

        long:       '<p>Du kannst schon vor dem Wahltag Deine Stimme abgeben: entweder direkt in Deinem Wahlamt oder per Briefwahl.</p>' +
                    '<p>Wenn Du <b>im Wahlamt wählen</b> willst, geh‘ einfach im Monat vor der Wahl zur Gemeindebehörde an Deinem Wohnort. Dort kannst du direkt wählen.</p>'+
                    '<p><b>Briefwahl</b> musst Du erst bei Deiner Gemeindebehörde beantragen. Das geht häufig auch über das Internet. Die Briefwahlunterlagen kannst Du Dir dann bequem schicken lassen – egal ob zu Dir nach Hause oder an Deinen Urlaubsort. Bei der letzten Bundestagswahl 2013 hat fast jeder vierte Wähler Briefwahl gemacht. Das waren also fast 11 Millionen Menschen – so viele wie nie zuvor.</p>'+
                    '<p>Wie und wo Du schon vor dem 24. September wählen kannst, steht auch auf Deiner Wahlbenachrichtigung. Die bekommt jeder, der wählen darf, vor der Wahl zugeschickt. Du musst allerdings nicht auf die Wahlbenachrichtigung warten, um vorher wählen zu können.</p>',
        links:      '<ul>' +
                        '<li><a href="http://www.bundeswahlleiter.de/bundestagswahlen/2017/informationen-waehler/briefwahl.html" target="_blank">Wie geht Briefwahl? (bundeswahlleiter.de)</a></li>' +
                        '<li><a href="http://wahlhilfe.bpb.de" target="_blank">Wie wähle ich? (wahlhilfe.bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/205714" target="_blank">Wie viele Menschen haben bei vergangenen Wahlen Briefwahl gemacht? (bpb.de)</a></li>' +
                    '</ul>'
    },
    {
        thesis:     'Politik interessiert mich nicht.',

        reaction:   'Aber sie betrifft dich.',
        short:      'Auch wenn Dir andere Dinge wichtiger sind – Politik hat Einfluss auf dein gesamtes Leben. Nur wenn Du wählst, kannst Du mitbestimmen.',

        long:       '<p>Umfragen zeigen immer wieder, dass sich <b>viele Menschen in Deutschland nicht für Politik interessieren</b>, weil ihnen andere Bereiche ihres Lebens wichtiger sind. Das gilt gerade auch für junge Menschen, auch wenn Studien zeigen, dass ihr Interesse an Politik insgesamt zunimmt – nur eben nicht an Parteien und klassischen Formen der politischen Teilhabe.</p>' +
                    '<p><b>Das ist ok.</b> Trotzdem sind Wählen und die Wahl wichtig. Denn Politik trifft "gesellschaftlich verbindliche Entscheidungen". Was heißt das?</p>' +
                    '<p><b>Was im Bundestag beschlossen wird, das gilt für uns alle.</b> Egal ob die Höhe der Steuern, die "Ehe für alle" oder die Voraussetzungen für öffentliches WLAN – darüber entscheiden stellvertretend für uns die Abgeordneten des Deutschen Bundestages. Und wir dürfen am 24. September bestimmen, wer solche Entscheidungen zukünftig für uns trifft.</p>' +
                    '<p>Selbst wenn wir uns nicht alle im Detail für Politik interessieren, sind wir also trotzdem von Politik betroffen – und können und sollten deswegen auch mitreden.</p>',
        links:      '<ul>' +
                        '<li><a href="http://www.shell.de/ueber-uns/die-shell-jugendstudie/jugend-und-politik.html" target="_blank">Wie hoch ist das Interesse an Politik bei Jugendlichen? (Shell-Jugendstudie, shell.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/234705" target="_blank">Warum haben junge Menschen weniger Interesse an Politik? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/39341" target="_blank">Welche Aufgaben hat der Bundestag? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/39346" target="_blank">Was machen die Abgeordneten? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/" target="_blank">Wie ensteht ein Gesetz? (bpb.de)</a></li>' +
                    '</ul>'
    },
    {
        thesis:     'Ich wähle aus Protest nicht.',

        reaction:   'Kann man machen …',
        short:      '… bringt aber nix. Wer protestiert, sollte dies mit einer Botschaft tun. Beim Nichtwählen ist die Botschaft unklar.',

        long:       '<p>In den 1970er Jahren wählten noch 90 Prozent der Wahlberechtigten. 2009 und 2013 waren es nur noch etwa 70 Prozent. Unter den <b>fast 18 Millionen Nichtwählerinnen und Nichtwählern</b> finden sich Uninteressierte und Erkrankte, Vergessliche und Enttäuschte, Überforderte und Gelangweilte, aber auch diejenigen, die – wie Du – aus Protest nicht wählen wollen.</p>' +
                    '<p>Diese letzte Gruppe hofft, durch die Nichtwahl eine politische Botschaft zu senden. Aber welche? Da die Motive zur Nichtwahl so vielfältig sind, <b>bleibt die Botschaft unklar</b> und deshalb können die Wahlverweigerer von der Politik leicht ignoriert werden.</p>' +
                    '<p>Wenn Länder mit unterschiedlicher Wahlbeteiligung verglichen werden, zeigt sich, dass sich die Parteien stärker an den Wünschen der gesamten Bevölkerung orientieren, wenn die Wahlbeteiligung hoch ist. Fällt Sie dagegen niedrig aus, wird vermehrt auf die Gruppen geachtet, die gewählt haben. Aus Protest nicht zu wählen, führt dazu, dass  <b>die eigenen Anliegen nicht wahrgenommen werden</b>, ändert aber wenig. Wenn Du protestieren möchtest, solltest Du andere Wege nutzen – und trotzdem wählen gehen.</p>',
        links:      '<ul>' +
                        '<li><a href="http://www.bpb.de/249615" target="_blank">Wie hat sich die Wahlbeteiligung entwickelt? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/172972" target="_blank">Warum wählen Menschen nicht? (bpb.de)</a></li>' +
                        '<li><a href="http://www.fluter.de/heft40" target="_blank">Welche Formen von Protest gibt es? (fluter.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/40484" target="_blank">Welche anderen Möglichkeiten politischer Beteiligung habe ich? (bpb.de)</a></li>' +
                    '</ul>'
    },
    {
        thesis:     'Meine Stimme ist egal!',

        reaction:   'Wirklich?',
        short:      'Jede Stimme zählt gleich – das ist der Kern der Demokratie. Und gerade bei knappen Wahlen gilt: Es kommt auf jede Stimme an!',

        long:       '<p>Dass bei Wahlen jede Person eine Stimme abgeben darf und alle Stimmen gleich viel zählen, ist Kern der Demokratie. Die <b>Gleichheit der Wahl</b> gehört daher in Deutschland zu den wichtigsten Grundsätzen. Dabei ist es egal, ob du reich oder arm, jung oder alt, berühmt oder unbekannt bist: "One man, one vote".</p>' +
                    '<p>Zugegeben: Deine Stimme ist nur eine unter Millionen. Aber: Die der anderen auch. Das Wahlergebnis ist am Ende eine <b>gemeinschaftliche Entscheidung</b>: Wenn Du wählst, wählst Du nicht nur für Dich, sondern auch für alle, die Deine politischen Einstellungen, Ziele oder Lebensumstände teilen. Als Gruppe habt ihr einen großen Einfluss auf den Wahlausgang – wenn Ihr wählt! Wenn Du dagegen aufs Wählen verzichtest, gewinnen andere Positionen an Gewicht.</p>' +
                    '<p>Besonders ärgerlich ist das dann, wenn der <b>Wahlausgang knapp</b> ist. Und das kommt öfter vor, als du denkst. Stell  Dir vor, am Ende fehlen Deiner favorisierten Partei nur ein paar Stimmen, um in den Bundestag zu kommen oder (mit-)regieren zu können – und Du hast nicht gewählt.</p>',
        links:      '<ul>' +
                        '<li><a href="http://www.bpb.de/249398" target="_blank">Welche Grundsätze gibt es im Wahlrecht? (bpb.de)</a></li>' +
                        '<li><a href="http://www.wahl-o-mat.de" target="_blank">Welche Parteien vertreten meine Positionen? (wahl-o-mat.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/206268" target="_blank">Wie viele Menschen dürfen wählen? (bpb.de)</a> </li>' +
                    '</ul>'
    },
    {
        thesis:     'Die Parteien sind eh alle gleich!',

        reaction:   'Ach ja?',
        short:      'Vergleichen lohnt sich! In manchem ähneln sich die Parteien wirklich, aber in wichtigen Punkten wollen sie Unterschiedliches.',

        long:       '<p>Bei der Bundestagswahl am 24. September kannst Du Dich zwischen <b>42 Parteien</b> entscheiden. Darunter finden sich christliche und sozialdemokratische Parteien, aber auch kommunistische und marktliberale, europafreundliche und europaskeptische Parteien, Tierschutzparteien, Parteien für Frauenrechte, für Mieter, für Familien, für die Vernunft oder für veganes Essen – und sogar für Hip Hop.</p>' +
                    '<p>Die Auswahl ist also groß und der Vergleich ist einfach möglich. In der Regel veröffentlichen alle Parteien eigene <b>Wahlprogramme</b>, in denen sie ihre Ziele erklären. Mit Angeboten wie dem <a href="http://www.wahl-o-mat.de">Wahl-O-Mat (wahl-o-mat.de)</a> kannst Du überprüfen, welchen politischen Aussagen Du zustimmst, und anschließend deine Positionen mit denen der verschiedenen Parteien vergleichen.</p>' +
                    '<p>Übrigens: Die Politikwissenschaft hat immer wieder untersucht, ob es einen Unterschied macht, wer regiert. Eine Mehrzahl der Studien zeigt, dass Parteien tatsächlich unterschiedliche Ziele verfolgen. Dies betrifft zwar nicht jede einzelne Frage, aber doch so viele Themen, dass wichtig ist, wer die Wahl gewinnt.</p>',

        links:      '<ul>' +
                        '<li><a href="https://www.bundeswahlleiter.de/info/presse/mitteilungen/bundestagswahl-2017/10_17_teilnahme.html" target="_blank">Welche Parteien treten an? (bundeswahlleiter.de)</a></li>' +
                        '<li><a href="http://www.wer-steht-zur-wahl-de" target="_blank">Wofür stehen die Parteien? (wer-steht-zur-wahl.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/42035" target="_blank">Was sind Parteien? (bpb.de)</a></li>' +
                    '</ul>'
    },
    {
        thesis:     'Wählen ändert nichts!',

        reaction:   'Mehr als Du denkst.',
        short:      'Regierungen setzen oft um, was sie ankündigt haben. Wer mit wem regiert und seine Forderungen durchsetzen kann, hängt vom Wahlergebnis ab.',

        long:       '<p>Was passiert eigentlich nach einer Bundestagswahl? Parteien bilden eine Regierung. In der Regel schließen sich dafür mehrere Parteien in einer <b>Koalition</b> zusammen.  Schon die Frage, welche Koalitionen sich bilden können, hängt vom Ausgang der Wahl ab: So haben sich seit 2002 bei jeder Bundestagswahl die Regierungskoalitionen verändert. Dafür gesorgt haben die Stimmen der Wählerinnen und Wähler.</p>' +
                    '<p>Auch Koalitionspartner können in bestimmten politischen Fragen anders denken. Sie müssen dann hart miteinander verhandeln, was sie als Regierung umsetzen wollen. Und die regierenden Parteien bemühen sich, das umzusetzen, was sie vor der Wahl angekündigt haben. Das belegen wissenschaftliche Studien. Weil in Koalitionen auch <b>Kompromisse</b> zwischen den Partnern gefunden werden müssen, gelingt das nicht immer für jede einzelne Partei.</p>' +
                    '<p>In jedem Fall aber heißt das: Ob sich etwas ändert, was sich ändert, was sich vielleicht auch gerade nicht ändern soll, das hängt stark davon ab, wie eine Bundestagswahl ausgeht. Und damit auch davon, ob und wie Du wählst.</p>',
        links:      '<ul>' +
                        '<li><a href="http://www.bpb.de/16467" target="_blank">Was ist eine Koalition? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/39365" target="_blank">Wie bildet sich die Bundesregierung? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/31751" target="_blank">Warum werden Kompromisse geschlossen? (bpb.de)</a></li>' +
                    '</ul>'
    },
    {
        thesis:     'Keiner meiner Freunde wählt.',

        reaction:   'Du kannst das ändern!',
        short:      'Wählen ist ansteckend – gerade im Familien- und Freundeskreis. Lass Dich nicht runterziehen, sondern zieh\' die anderen mit!',

        long:       '<p>Wählen ist etwas, das wir gemeinsam tun. Das gilt im Großen: 61,5 Millionen Deutsche sind bei der Bundestagswahl 2017 wahlberechtigt. Es gilt aber auch im Kleinen: Viele Familien gehen gemeinsam ins Wahllokal – oder bleiben gemeinsam zu Hause. Auch in Freundeskreisen gilt häufig: <b>Entweder wählen fast alle oder fast niemand.</b> Eine <a href="http://www.wahlbeteiligung2013.de/fileadmin/Inhalte/Studien/BST-Studie_Politische_Partizipation_2013.pdf">Studie des Instituts für Demoskopie Allensbach</a> etwa zeigt: 76 Prozent derer, die von wählenden Freunden umgeben sind, gehen selbst "bestimmt zur Wahl". Geht nur eine Minderheit der Freunde zur Wahl, sinkt der Wert auf 10 Prozent.</p>' +
                    '<p>Studien zeigen aber auch, dass eine einzige Person hier einen Unterschied machen kann. <b>Wählen ist ansteckend</b>: Wenn Deine Freunde also nicht wählen – "make a difference", zieh\' sie mit.</p>' +
                    '<p>Warum Du das tun solltest? Gerade <b>junge Wahlberechtigte sind in Deutschland weniger wahlfreudig als ältere</b>: Für die Bundestagswahl 2013 <a href="https://www.bundeswahlleiter.de/dam/jcr/cd805e63-c083-4f8b-ad7a-561b2fa110fd/btw13_heft4.pdf">hat der Bundeswahlleiter gezeigt</a>: Unter 30 liegt die Wahlbeteiligung bei rund 60 Prozent, zwischen 60 und 70 dagegen bei fast 80 Prozent.</p>' +
                    '<p>Wenn aber mehr junge Menschen wählen würden, dann würden ihre Wünsche und Bedürfnisse in der Politik auch mehr Berücksichtigung finden.</p>',
        links:      '<ul>' +
                        '<li><a href="http://www.bpb.de/249503" target="_blank">Wer ist wahlberechtigt? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/205686" target="_blank">Wie unterscheidet sich die Wahlbeteiligung bei alten und jungen Menschen? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/61541" target="_blank">Wie stark sind welche Altersgruppen in der Bevölkerung vertreten? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/247705" target="_blank">Wie kann ich mitbestimmen? (bpb.de)</a></li>' +
                    '</ul>'
    },
    {
        thesis:     'Hier bei uns wählt niemand.',

        reaction:   'Nur wer wählt, zählt.',
        short:      'Wenn nur wenige Menschen in einer Gegend wählen, werden ihre Anliegen von der Politik nicht wahrgenommen. Das kannst Du ändern!',

        long:       '<p><b>Die Wahlbeteiligung in Deutschland ist oft ungleich verteilt.</b> In großen Städten liegt die Beteiligung in manchen Stadtteilen bei fast 90 Prozent, in anderen dagegen nur bei 50 Prozent. Das ist problematisch:</p>' +
                    '<p>Erstens ist die Beteiligung aller an der Demokratie ein hohes Gut. Und zweitens sind damit auch Folgen für die politischen Inhalte verbunden, die nach der Wahl umgesetzt werden. Sehr große Beteiligungsunterschiede können dazu führen, dass sich Parteien und Politiker <b>eher an denen orientieren, die wählen gehen</b> – ihre Stimme wird besser gehört. Wenn bei Euch niemand wählt, schadet Ihr also Euch selbst.</p>' +
                    '<p>Ob die Beteiligung in einer Region hoch oder niedrig ist, ist nicht zufällig, sondern folgt klaren Mustern. Ein Beispiel: Wo Menschen weniger verdienen, gehen auch weniger wählen. Das haben Studien zur Bundestagswahl 2013 gezeigt. <b>So können ihre Probleme unsichtbar bleiben.</b> Deshalb sollten gerade sie wählen gehen – denn nur wer wählt, zählt.</p>',
        links:      '<ul>' +
                        '<li><a href="http://wahlatlas.net/btw/17/#!bet13z,default" target="_blank">Wo ist die Wahlbeteiligung hoch und niedrig? (wahlatlas.net)</a></li>' +
                        '<li><a href="http://www.bpb.de/61785" target="_blank">Wer ist von Armut besonders gefährdet? (bpb.de)</a></li>' +
                        '<li><a href="http://www.bpb.de/234703" target="_blank">Warum ist niedrige Wahlbeteiligung ein Problem und was kann man dagegen tun? (bpb.de)</a></li>' +
                    '</ul>'
    }
    ],

    contentGrundgesetz: [
      {
        thesis: '67',
        short: '67 Grundgesetzänderungen gab es zwischen 1949 und Ende 2022.',
      },
      {
        thesis: '3',
        short:
          'Artikel 31 „Bundesrecht bricht Landesrecht“ ist mit 3 Worten der kürzeste.',
      },
      {
        thesis: '4',
        short:
          '4 Frauen und 61 Männer waren im Parlamentarischen Rat und berieten über das GG.',
      },
      {
        thesis: '55',
        short:
          'Durchschnittlich 55 Jahre alt waren die Väter und Mütter des Grundgesetzes.',
      },
      {
        thesis: '146',
        short:
          '146 Artikel hat das GG seit 1949 – und dennoch ist es gewachsen: etwa durch Ergänzungen in den einzelnen Artikeln.',
      },
      {
        thesis: '744',
        short: 'Der Grundgesetzartikel 106 ist der längste mit 744 Wörtern und regelt das Steueraufkommen.',
      },
      {
        thesis: '0',
        short:
          'Fünf Grundgesetzartikel sind weggefallen und haben 0 Wörter, wie etwa der Artikel 75.',
      },
      {
        thesis: '5',
        short:
          '5 Grundgesetzartikel (136, 137, 138, 139 und 141) stammen aus der Weimarer Reichsverfassung.',
      },
      {
        thesis: '11',
        short:
          '11 Landtage haben 1949 über das Grundgesetz abgestimmt. Ein Landtag, der Bayerische, lehnte es ab.',
      },
    ]
  };

  const faq = {
      title:    'Fragen und Antworten',
      content:  '<h3>Wahlbingo?</h3>' +
                '<p>Das Wahlbingo besteht aus neun Karten, die sich bei „Klick“ umdrehen. Auf jeder Karte steht eine These, dahinter eine Erwiderung. Das Ganze funktioniert dann wie ein klassisches Bingo. Hat man eine Reihe (diagonal, senkrecht oder waagerecht) mit drei Karten aufgedeckt, hat man „Bingo“!</p>' +
                '<h3>Was soll das Ganze?</h3>' +
                '<p>In Deutschland gibt es keine Wahlpflicht. Nicht wählen zu gehen ist daher eine legitime (politische und persönliche) Entscheidung – hat aber Konsequenzen. Diese wollen wir mit dem Wahlbingo deutlich machen.</p>' +
                '<p>Das Wahlbingo richtet sich vor allem an Jung- und Erstwähler/-innen. Es soll dazu anregen, sich mit üblichen Argumenten, die auf den ersten Blick gegen das Wählen oder für das Nicht-Wählen sprechen, auseinanderzusetzen und diese zu hinterfragen. Zugleich soll es aufzeigen, warum Wählen wichtig und eine wertvolle Möglichkeit ist, am politischen Prozess teilzunehmen.</p>' +
                '<h3>Wer hat’s gemacht?</h3>' +
                '<p>Text und wissenschaftliche Beratung: <a href="http://www.thorstenfaas.de/" title="Link zur Webseite von Thorsten Faas" target="_blank">Thorsten Faas</a> (Prof., Universität Mainz), <a href="http://www.armin-schaefer.de/" title="Link zur Webseite von Armin Schäfer" target="_blank">Armin Schäfer</a> (Prof., Universität Osnabrück)</p>' +
                '<p>Konzept und Redaktion (bpb): Thomas Fettien, Michelle Trimborn</p>' +
                '<p>Technische Umsetzung und Design: <a href="https://3pc.de/" title="Link zur Webseite von 3pc" target="_blank">3pc GmbH, Berlin</a></p>'
    };

  let modal = {
    aElements: document.querySelectorAll('a'),

    links: document.querySelectorAll('.js_modal-link, .js_faq-link'),
    activeLinkModal: null,

    closeModal: null,
    overlay: null,
    window: null,
    content: null,

    closeFocus: null,

    template: (function () {
      let overlay = document.createElement('div');
                let window = document.createElement('div');
                let windowContent = document.createElement('div');
                let close = document.createElement('button');

      close.className += ' js_modal-close o_modal-close';
      close.innerHTML = '<span class="sr-only">Dialog schließen</span> <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="black" d="M18.85 0c-.29 0-.58.11-.8.34L10 8.38 1.95.34a1.13 1.13 0 0 0-1.61 0 1.14 1.14 0 0 0 0 1.62L8.38 10 .34 18.04a1.14 1.14 0 0 0 0 1.62c.22.23.51.34.81.34.29 0 .58-.11.8-.34L10 11.62l8.05 8.04c.22.23.51.34.8.34.3 0 .59-.11.81-.34.45-.44.45-1.17 0-1.61L11.62 10l8.04-8.05c.45-.44.45-1.17 0-1.61a1.1 1.1 0 0 0-.81-.34"/></svg>';
      overlay.className += ' js_modal-overlay o_modal-overlay';
      window.className += ' js_modal-window o_modal-window';
      window.setAttribute('role', 'dialog');
      window.setAttribute('aria-labelledby', 'o_dialog-title');
      window.setAttribute('aria-describedby', 'o_dialog-description');
      window.setAttribute('tabindex', 0);
      windowContent.className += ' js_modal-content o_modal-window__content';
      // windowContent.setAttribute('tabindex', 0);

      window.appendChild(close);
      window.appendChild(windowContent);
      overlay.appendChild(window);

      return overlay;
    })(),

    init: function () {
      for (let i = 0; i < modal.links.length; i++) {
        modal.links[i].addEventListener('click', () => {
          activeLinkModal = modal.links[i]
          modal.open()
        });
      }
    },

    open: function () {
      activeLinkModal.setAttribute('aria-expanded', 'true');

      body.className += ' o_modal-lock';
      body.appendChild(modal.template);

      modal.content = document.querySelector('.js_modal-content');
      modal.window = document.querySelector('.js_modal-window');
      modal.overlay = document.querySelector('.js_modal-overlay');
      modal.closeModal = document.querySelector('.js_modal-close');

      let tile = h_parentsWithClass(activeLinkModal, 'js_tile-proper');
      let modalTitle;
      let modalTxt;

      modal.closeFocus = activeLinkModal;

      if (tile.length) {
        tile = tile[0];
        modalTitle = tile.querySelector('.js_tile-front_title').innerHTML;
        modalTxt = tile.querySelector('.js_tile-back_long').innerHTML;
      } else {
        modalTitle = faq.title;
        modalTxt = faq.content;
      }

      modal.content.innerHTML =
        '<h2 id="o_dialog-title">' +
        modalTitle +
        '</h2>' +
        '<p id="o_dialog-description">' +
        modalTxt +
        '</p>';

      modal.closeModal.focus();
      modal.updateDimensions();
      modal.bindKeyboardTrap();

      modal.closeModal.addEventListener('click', modal.close);
      modal.overlay.addEventListener('click', modal.close);
      window.addEventListener('keyup', modal.keyEsc);
    },

    close: function (e, triggeredByEscKey = false) {
      e.stopPropagation();

      // Check whether the click event was fired from a modal wrapper child or by esc allowed to close the modal.
      if (
        (h_hasClass(e.target, 'js_modal-overlay') ||
        h_hasClass(e.currentTarget, 'js_modal-close')) || triggeredByEscKey
      ) {
        activeLinkModal.setAttribute('aria-expanded', 'false');
        activeLinkModal = null;

        modal.overlay.removeEventListener('click', modal.close);
        modal.closeModal.removeEventListener('click', modal.close);
        modal.closeModal.removeEventListener('click', modal.close);
        window.removeEventListener('keyup', modal.keyEsc);
        modal.unBindKeyboardTrap();

        modal.closeFocus.focus();
        modal.closeFocus = null;

        body.removeChild(modal.overlay);

        modal.overlay = null;
        modal.closeModal = null;
        modal.window = null;
        modal.content = null;

        h_removeClass(body, 'o_modal-lock');
      }
    },

    updateDimensions: function () {
      modal.overlay.style.height = v_screenHeight + 'px';
      modal.content.style.maxHeight = v_screenHeight - 160 + 'px';
      modal.window.style.maxWidth = bingo.field.clientWidth + 'px';
      modal.window.style.marginLeft = '-' + modal.window.clientWidth / 2 + 'px';
      modal.window.style.marginTop = '-' + modal.window.clientHeight / 2 + 'px';
      // modal.overlay.style.top = v_scrollDistanceTop+'px';
      // modal.overlay.style.width = v_screenWidth+'px';
    },

    keyEsc: function (event) {
      if (event.key === 'Escape') modal.close(event, true)
    },

    bindKeyboardTrap: function () {
      const firstFocusableElement = modal.closeModal;
      const lastFocusableElement = [...modal.window.querySelectorAll('a, button')].at(-1);

      firstFocusableElement.addEventListener('keydown', modal.trapFirstElement)
      if (lastFocusableElement) {
        lastFocusableElement.addEventListener('keydown', modal.trapLastElement)
      }
    },

    unBindKeyboardTrap: function () {
      const firstFocusableElement = modal.closeModal;
      const lastFocusableElement = [...modal.window.querySelectorAll('a, button')].at(-1);

      firstFocusableElement.removeEventListener('keydown', modal.trapFirstElement)
      if (lastFocusableElement) {
        lastFocusableElement.removeEventListener('keydown', modal.trapLastElement)
      }
    },

    trapFirstElement: function (event) {
      if (event.shiftKey && event.keyCode == 9) {
        event.preventDefault()
        const lastFocusableElement = [...modal.window.querySelectorAll('a, button')].at(-1);
        lastFocusableElement.focus()
      }
    },

    trapLastElement: function (event) {
      if(!event.shiftKey && event.keyCode == 9) {
        event.preventDefault()
        modal.closeModal.focus();
      }
    },
  };

  const socialMedia = {
    element: document.querySelector('.js_socialmedia'),

    positioning: function () {
      let diff = v_screenWidth - this.element.clientWidth;
      if (diff > 0) {
        this.element.style.left =
          (v_screenWidth - this.element.clientWidth) / 2 - 13 + 'px'; // -13px correctional substraction of scrollbar width
      }
    },
  };

  // ====================================== EXECUTION TIMING ======================================

  /// / --- ON WINDOW LOAD --- ////

  window.addEventListener(
    'load',
    function load(event) {
      window.removeEventListener('load', load, false);

      h_screenWidth();
      h_screenHeight();
      h_scrollDistanceTop();
      bingo.init();
      socialMedia.positioning();
      modal.init();
    },
    false
  );

  /// / --- ON WINDOW RESIZE --- ////

  let resizeTimer;
  window.onresize = function (event) {
    h_screenWidth();
    h_screenHeight();
    h_scrollDistanceTop();

    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      tile.syncUpHeight();
      bingo.syncUpHeight();
      if (modal.overlay) {
        modal.updateDimensions();
      }
      socialMedia.positioning();
    }, 250);
  };

  /// / --- ON WINDOW SCROLL --- ////

  // let scrollTimer;
  window.onscroll = function (event) {
    h_scrollDistanceTop();

    // clearTimeout(scrollTimer);
    // scrollTimer = setTimeout(function() {
    //
    // }, 250);
  };

  // ====================================== MODULE API ======================================

  return {};
})(window, document);
